import {useEffect} from "react";

export const GoogleTagManager = ({adsenseClientId, tagName}) => {

    useEffect(() => {

        if (adsenseClientId && !document.getElementById(`${tagName}`)) {
            const adsenseScript = document.createElement('script');
            adsenseScript.id = tagName;
            adsenseScript.async = true;
            adsenseScript.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adsenseClientId}`;
            adsenseScript.crossOrigin = 'anonymous';
            document.head.appendChild(adsenseScript);
        }


    }, [adsenseClientId, tagName]);

    return null;
}