import {Routes, Route} from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import './App.css';
import Header from "./components/Header/Header";
import BranchList from './pages/BranshList/BranchList';
import BranchMenuList from "./pages/BranchItem/BranchMenuList/BranchMenuList";
import AboutBranch from "./pages/AboutBranch/AboutBranch";
import MastersList from "./pages/MastersList/MastersList";
import FoolMasterInfo from "./pages/FoolMasterInfo/FoolMasterInfo";
import React, {useState} from 'react';
import Services from "./pages/Services/Services";
import Reservation from "./pages/Reservation/Reservation";
import DateAndTime from "./pages/DateAndTime/DateAndTime";
import ConfirmedReservation from "./pages/ConfirmedReservation/ConfirmedReservation";
import CancelReservation from "./pages/CancelReservation/CancelReservation";
import Canceled from "./pages/CancelReservation/Canceled/Canceled";
import Reviews from "./pages/Reviews/Reviews";
import MasterReviews from "./pages/Reviews/MasterReviews/MasterReviews";
import ReviewAlreadyLeft from "./pages/Reviews/ReviewAlreadyLeft";
import PageNotFound from "./components/ErrorComponents/PageNotFound/PageNotFound";
import HrefToMePos from "./components/HrefToMePos/HrefToMePos";
import { hotjar } from 'react-hotjar';
import {GoogleTagManager} from "./components/GoogleTagManager/GoogleTagManager";
import {useQuery} from "@apollo/client";
import {GET_IS_PREMIUM} from "./apollo/Queries";
import Spinner from "./components/Spinner/Spinner";


function App() {
    const ADSENSE_CLIENT_ID = 'ca-pub-7056109210876208';
    const ADSENSE_SCRIPT_ID = 'google-adsense-script';

    const HOTJAR_ID = 4953864;
    const HOTJAR_VERSION = 6;

    const options = {
        id : HOTJAR_ID,
        sv : HOTJAR_VERSION
    }

    hotjar.initialize(options);
    hotjar.event('button-click');

    const [isPremium, setIsPremium] = useState(false);
    const pathname = window.location.pathname;
    const companyId = pathname.split('/')[2];

    const {loading} = useQuery(GET_IS_PREMIUM, {
        variables : {companyId : parseInt(companyId)},
        onCompleted : (data) => {
            setIsPremium(data.isPremiumOn)
        }
    })

    if (loading) return <Spinner/>


    return (
        <div className="App bg-light main d-flex flex-column">
            <Header/>
            <div className={"flex-grow-1 mb-5"}>
                <Routes>
                    <Route path='/' element={<HrefToMePos/>}/>
                    <Route path='/company/:companyId' element={<BranchList/>}/>
                    <Route path={'/company/:companyId/about-branch/:branchId/:from?'} element={<AboutBranch/>}/>
                    <Route path={'/company/:companyId/branch-menu-list/:branchId'} element={<BranchMenuList/>}/>
                    <Route path='/company/:companyId/branch-menu-list/:branchId/masters' element={<MastersList/>}/>
                    <Route path='/company/:companyId/branch-menu-list/:branchId/masters/:masterId'
                           element={<FoolMasterInfo/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/services" element={<Services/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/reservation" element={<Reservation/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/set-date" element={<DateAndTime/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/reservation/confirmed/:bookingUuid"
                           element={<ConfirmedReservation/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/reservation/cancel/:bookingUuid"
                           element={<CancelReservation/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/reservation/canceled/:bookingUuid"
                           element={<Canceled/>}/>
                    <Route path="/company/:companyId/reviews/:bookingUuid" element={<Reviews/>}/>
                    <Route path="/company/:companyId/branch-menu-list/:branchId/masters/:masterId/reviews" element={<MasterReviews/>}/>
                    <Route path={'/company/:companyId/reviews/:uuid/review-is-left'} element={<ReviewAlreadyLeft/>}/>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </div>
            {/*<Advertising/>*/}
            {!isPremium && <GoogleTagManager tagName={ADSENSE_SCRIPT_ID} adsenseClientId={ADSENSE_CLIENT_ID}/>}
          </div>
    );
}

export default App;
