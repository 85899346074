import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import cancelImage from './cancelLogo1.png'
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {CANCEL_BOOKING} from "../../apollo/Mutations";
import {useState} from "react";
import Spinner from "../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";

const CancelReservation = () => {

    const {t : translate} = useTranslation();
    const {companyId, branchId, bookingUuid} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cancelBooking] = useMutation(CANCEL_BOOKING, {
    onCompleted :() => {
        setLoading(false)
        navigate(`/company/${companyId}/branch-menu-list/${branchId}/reservation/canceled/${bookingUuid}`)
    }})
    const cancelBookingHandler = () => {
        cancelBooking({variables : {uuid : bookingUuid}});
        setLoading(true);
    }

    return (
        <>
            <NavigationComponent
                navLink={`/company/${companyId}/branch-menu-list/${branchId}/reservation/confirmed/${bookingUuid}`}
                linkText={translate('cancelPage.toDetailsReservation')}
            />
            {loading ?
                <Spinner/>
                :
                <ContentWrapper>
                    <div className="cancel-content py-5 px-5 d-flex justify-content-center align-items-center flex-column">
                        <div className="img-wrapper mb-4">
                            <img src={cancelImage} alt="" className='d-block'/>
                        </div>
                        <h5 className="main_text_color mb-3">
                            {translate('cancelPage.cancelReservation')}?
                        </h5>
                        <p className='text-secondary px-3 text-center pb-5 mb-5'>
                            {translate('cancelPage.cancelReservationInfo')}?
                        </p>
                    </div>
                    <div className={"row"}>
                        <div className={"col-lg-6 mb-3"}>
                            <Link to={`/company/${companyId}`}
                                  className={"btn btn-primary w-100 fw-bold fs-7 rounded-1"}>
                                {translate('cancelPage.noBtn')}
                            </Link>
                        </div>

                        <div className={"col-lg-6"}>
                            <button
                                onClick={cancelBookingHandler}
                                className="btn btn-outline-primary w-100 rounded-1">
                                {translate('cancelPage.yesBtn')}
                            </button>
                        </div>
                    </div>
                </ContentWrapper>}
        </>
    )
}

export default CancelReservation