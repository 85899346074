import cancelImage from "./cancelLogo2.png";
import {Link, useParams} from "react-router-dom";
import ContentWrapper from "../../../UI/ContentWrapper/ContentWrapper";
import {convertDate} from "../../../utils/workingWithDateTime/formatDateTime";
import {useQuery} from "@apollo/client";
import {BOOKING_CANCELED_INFO} from "../../../apollo/Queries";
import {apolloClientPosBranch} from "../../../apollo/ApolloClients";
import {useState} from "react";
import Spinner from "../../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";
import {NavigationComponent} from "../../../components/NavigationComponent/NavigationComponent";

const Canceled = () => {

    const {t : translate} = useTranslation();
    const {companyId, bookingUuid} = useParams();
    const [loading, setLoading] = useState(true);

    const {data: bookingData} = useQuery(BOOKING_CANCELED_INFO,
        {
            client: apolloClientPosBranch, variables: {id: bookingUuid}, onCompleted: () => {
                setLoading(false);
            }
        });

    return (
        <>
            <NavigationComponent
                navLink={`/company/${companyId}`}
                linkText={translate('toMainPage')}
            />
            {(bookingData && bookingData.booking && !loading) ?
                <ContentWrapper>
                    <div className="cancel-content py-5 px-5 d-flex justify-content-center align-items-center flex-column">
                        <div className="img-wrapper mb-4">
                            <img src={cancelImage} alt="" className='d-block'/>
                        </div>
                        <h5 className="main_text_color mb-3">
                            {translate('cancelPage.reservationIsCanceled')}
                        </h5>
                        <p className='text-secondary px-3 text-center pb-5 mb-5'>
                                {`${translate('cancelPage.canceledInfoTo')} 
                            ${convertDate(new Date(bookingData.booking.date))} ${translate('cancelPage.toSalon')} 
                            ${bookingData.booking?.branch.name}, ${translate('cancelPage.city')}.${bookingData.booking?.branch.city.name} ${translate('cancelPage.canceled')}.`}
                        </p>
                    </div>
                    <div className={"row"}>
                        <div className={"col-lg-6 col-md-6 mx-auto"}>
                            <Link to={`/company/${companyId}`} className={"btn btn-primary w-100 fw-bold fs-7 rounded-1"}>
                                {translate('confirmedInfo.createNewBooking')}
                            </Link>
                        </div>
                    </div>
                </ContentWrapper>
            : <Spinner/>}
        </>
    )
}

export default Canceled