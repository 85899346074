import {gql} from '@apollo/client';

export const COMPANY = (id) => {
    return gql`
    {
            company(id: ${id}) {
            id
            name
            country {
            isoCode
            }
            language {
            isoCode
            }
            image
            confirmationCallRequired
            showAds
        }
    }
`
};

export const COMPANY_BRANCHES = (id) => {
    return gql`
{
  companyBranches(id: ${id}) {
    id
    city {
      name
    }
    name
    address
    workSchedule
    timeZone
    closestBookingTimes {
      start(format: "H:i:s")
      end(format: "H:i:s")
    }
    closestBookingDate(format: "Y-m-d")
  }
}`
};

export const COMPANY_BRANCH = (id) => {
    return gql`
{
  branch(id: ${id}) {
    id
    city {
      name
    }
    dateTimeFormat {
      id
      format
    }
    description
    name
    timeZone
    address
    phones {
      phone
    }
    website
    image
    workSchedule
    closestBookingTimes {
      start(format: "H:i:s")
      end(format: "H:i:s")
    }
    serviceImages
    branchImages
    closestBookingDate(format: "Y-m-d")
  }
}`
}

export const COMPANY_BRANCH_INFO = (id) => {
    return gql`
{
  branch(id: ${id}) {
    city {
      name
    }
    name
    address
    workSchedule
    timeZone
  }
}`
}
export const EMPLOYER_LIST = gql`
  query employees($branchId: ID!, $picketDate: DateTime, $serviceId: ID, $online: Boolean!) {
    employees(branchId: $branchId, dateTime: $picketDate, serviceId: $serviceId, online : $online) {
    id
    image
    name
    surname
    aboutMe
    specialization
      position {
      name
    }
    employeeServiceRelation {
      price
      duration(format: "H:i:s")
    }
    closestBookingDateTimes {
      start(format: "H:i:s")
      end(format: "H:i:s")
    }
    avgRating
    reviewCount
    closestBookingDate(format: "Y-m-d")
    }
  }
`;
export const EMPLOYER_LIST_FOR_DEFAULT_MASTER = gql`
  query employees($branchId: ID!, $picketDate: DateTime, $serviceId: ID) {
    employees(branchId: $branchId, dateTime: $picketDate, serviceId: $serviceId) {
    id
    allowDefault
}}`;

export const EMPLOYER = (branchId, employeeId, serviceId) => {
    return gql`
{
  employee(branchId: ${branchId}, employeeId: ${employeeId}, serviceId: ${serviceId}) {
    id
    image
    name
    aboutMe
    surname
    specialization
    position {
      name
    }
    employeeServiceRelation {
      price
      duration(format: "H:i:s")
    }
    closestBookingDateTimes {
      start(format: "H:i:s")
      end(format: "H:i:s")
    }
    avgRating
    reviewCount
    lastReviews {
      id
      rating
      name
      text
      createdAt(format: "Y-m-d H:i:s")
    }
    qualificationConfirmations {
      image
    }
    closestBookingDate(format: "Y-m-d")
  }
}`
}

export const SERVICE_LIST = gql`
  query Services($branchId: ID!, $employeeId: ID, $dateTime: DateTime, $online : Boolean!) {
    services(branchId: $branchId, employeeId: $employeeId, dateTime: $dateTime, online : $online) {
      id
      name
      image
      description
      duration
      price
      currencyCode
    }
  }
`;

export const BOOKING_TIMES = (currentDate, branchId, employeeId, serviceId, online) => {
    return gql`
{
  bookingTimes(
    date: "${currentDate}"
    branchId: ${branchId}
    employeeId: ${employeeId}
    serviceId: ${serviceId}
    online : ${online}
  ) {
    start(format: "H:i:s")
    end(format: "H:i:s")
  }
}`
}


export const BOOKING_DATES = (branchId, employeeId, serviceId, online) => {
    return gql`
{
  bookingDates(branchId: ${branchId}, employeeId: ${employeeId}, serviceId: ${serviceId}, online : ${online}) {
    start(format: "Y-m-d")
    end(format: "Y-m-d")
  }
}`
}

export const GET_BOOKING = gql`
  query GetBooking($uuid: Uuid!) {
    booking(uuid: $uuid) {
      uuid
      status {
        id
        name
      }
      service {
        id
        name
      }
      employee {
        name
        surname
      }
      price
      branch {
        city {
          name
        }
        name
        address
        currencyCode
      }
      date(format: "Y-m-d")
      time(format: "H:i:s")
      endTime(format: "H:i:s")
      notificationTime
    }
  }
`;

export const BOOKING_CANCELED_INFO = gql`
  query GetBooking($uuid: Uuid!) {
    booking(uuid: $uuid) {
      branch {
        city {
          name
        }
        name
      }
      date(format: "Y-m-d")
    }
  }
`;

export const BOOKING_REVIEWS_INFO = gql`
    query GetBooking($uuid: Uuid!) {
        booking(uuid: $uuid) {
            service {
                id
                name
            }
            employee {
                name
                surname
            }
            client {
                name
            }
            branch {
                city {
                    name
                }
                name
                address
            }
            date(format: "Y-m-d")
            time(format: "H:i:s")
        }
    }
`;

export const GET_REVIEWS = gql`
  query GetReviews($employeeId: ID!, $sortField: ReviewSortField!, $order: Order!, $page: Int, $itemsPerPage: Int) {
    reviews(
      employeeId: $employeeId
      sortField: $sortField
      order: $order
      page: $page
      itemsPerPage: $itemsPerPage
    ) {
      reviews {
        id
        rating
        name
        text
        createdAt(format: "Y-m-d H:i:s")
      }
      reviewCount
      currentPage
      itemsPerPage
      pageCount
      employee {
      name
      surname
      specialization
      employeePosition {
        name
      } 
    }
    }
  }
`;

export const bookingValidate = gql`
  query ValidateBooking($dateTime: DateTime!, $serviceId: Int!, $employeeId: Int!, $branchId: Int!) {
    validateBooking(input: {
      dateTime: $dateTime,
      serviceId: $serviceId,
      employeeId: $employeeId,
      branchId: $branchId
    })
  }
`;

export const IS_ANY_REVIEW_PER_BOOKING = gql`
  query IsAnyReviewPerBooking($uuid: Uuid!) {
    isAnyReviewPerBooking(uuid: $uuid)
  }
`;

export const GET_IS_PREMIUM = gql`
  query isPremiumOn ($companyId : Int!) {
    isPremiumOn(companyId: $companyId)
  }
`;